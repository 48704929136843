export default {
  data() {},
  methods: {
    onClickLeft() {
      this.$router.back(-1);
    },
    gologin(status) {
      if (status) {
        if (this.$route.query.myback) {
          this.$router.back(-1);
          return;
        } else {
          this.$router.replace({
            path: '/login',
            query: {
              status
            }
          });
          return;
        }
      }
      if (localStorage.getItem('dzmmtoken')) {
        localStorage.removeItem('dzmmtoken');
      }
      this.$router.replace({
        path: '/login'
      });
    }
  }
};